import React,{useState,useEffect,useRef} from 'react'
import {navigate} from 'gatsby'
import {getLocationData} from '../location_data'
import './style.scss'
import { isMobile,isAndroid,isWinPhone,isIOS,isTablet,deviceType } from 'react-device-detect'
const LetsTalkPopup = ({show,handlePopup}) =>{
    const windowGlobal = typeof window !== 'undefined' && window
    const wr = useRef(null);
    const [formdata,setFormdata]=useState({[`quote-email`]:null,[`quote-phone`]:null})
    const [subscribed,setSubscribed]= useState(false)
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }
      const handleSubmit=(e)=>{
        const form = e.target
        const userrole = []
        const lifecycledata = []
        let expert = null
        if(windowGlobal){
            if(windowGlobal.localStorage.getItem('roleData')){
                const t = JSON.parse(windowGlobal.localStorage.getItem('roleData'))
                t.forEach(el=>{
                    if(el.checked === true){
                        userrole.push(el.role)
                    }
                })
            }
            if(windowGlobal.localStorage.getItem('lifecycledata')){
                const s = JSON.parse(windowGlobal.localStorage.getItem('lifecycledata'))
                s.forEach(el=>{
                    if(el.checked === true){
                        lifecycledata.push(el.name)
                    }
                })
            }
            if(windowGlobal.localStorage.getItem('expert_name')){
                expert = windowGlobal.localStorage.getItem('expert_name')
              }
        }
        fetch("/", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": form.getAttribute('name'),
                             ...formdata ,
                             "User_roles":userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                             "Company_lifecycle":lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,
                             "Expertise":expert,
                             "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                            "Country":getLocationData('country_name'),
                            "Zip":getLocationData('zipcode'),
                            "User_country":getLocationData('country_name'),
                            "City":getLocationData('city'),
                            "Latitude":getLocationData('latitude'),
                            "Longitude":getLocationData('longitude'),
                            "State":getLocationData('state_prov'),
                            })
          })
            .then(() =>{
                 if(windowGlobal){
                        windowGlobal.localStorage.setItem('email',formdata["quote-email"])
                        if(Number(formdata["quote-phone"])){
                        windowGlobal.localStorage.setItem('number',formdata["quote-phone"])
                        }
                    }
                setSubscribed(true)
                setTimeout(() => {
                    navigate('/thank-you-page')
                }, 1000);
            })
            .catch(error => alert(error));
          e.preventDefault();
      }
      const formonChange =(e)=>{
        setFormdata({...formdata,[e.target.name]:e.target.value})
        console.log(formdata,'formdata')
      }
      useEffect(()=>{
      
          if(show && windowGlobal.innerWidth > 1023 && !isMobile){
            wr.current.focus()
          }
        if(window){
            if(localStorage.getItem('email') && localStorage.getItem('number')){
               const email = localStorage.getItem('email')
               const phone = localStorage.getItem('number')
               setFormdata({[`quote-email`]:email,[`quote-phone`]:phone})
              }else if(localStorage.getItem('email')){
                const email = localStorage.getItem('email')
                setFormdata({[`quote-email`]:email})
              }else if(localStorage.getItem('number')){
                const phone = localStorage.getItem('number')
                setFormdata({...formdata,[`quote-phone`]:phone})
              }
             
          
        }
      },[subscribed,show])
        return(
        <div className={`span12 widget-span widget-type-cell request-quote-popup-layout slide-popup ${show ? `show`:``}`}>

        <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell request-quote-popup">
        <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
        <div className="row-fluid ">
        <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_jinja english-head-pop" >
                <a to="#" style={{cursor:'pointer'}} className="close" onClick={()=> handlePopup('close')}></a>
                    <span className="en-text">
                        <h4>Let’s talk</h4>
                    <p>and discuss your business needs with our technical specialist</p>
                    </span>
                    <form 
                    id="qoutes-form" 
                    name="let's talk."
                    method="post"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    autocomplete="off"
                    onSubmit={handleSubmit} >

                    <input type="hidden" name="form-name" value="let's talk." />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={formonChange} />
                    </label>
                    </p>
                   
                    <input 
                    ref={wr}
                    type="email" 
                    id="quote-email" 
                    name="quote-email" 
                    value={formdata[`quote-email`]}
                    required = {true}
                    onChange = {formonChange}
                    placeholder="Email Address"/>
                    <input 
                    type="text" 
                    id="quote-phone" 
                    name="quote-phone" 
                    required = {false}
                    value={formdata[`quote-phone`]}
                    onChange = {formonChange}
                    placeholder="Telephone / Skype"/>
                    <input type="submit" value="Contact"/>
                    <p hidden>
                    <input name="User_roles" />
                    <input name="Company_lifecycle" />                
                    <input name="Expertise" />                   
                    <input name="Language" />
                    <input name="Country" />
                    <input name="Zip" />
                    <input name="User_country" />
                    <input name="City" />
                    <input name="Latitude" />
                    <input name="Longitude" />
                    <input name="State" />
                    </p>
                    </form>
                    <div className="thankyouwrapper" style={{display: subscribed ? `block`:``}}>
                    <div className="thankyou">
                        <h4>Thank You.</h4>
                        <p>We will answer as soon as possible.</p>
                    </div> 
                    </div>
                
            </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        )
}
export default LetsTalkPopup;