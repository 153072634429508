import React,{useState, useEffect} from 'react'
import { Link, navigate } from 'gatsby'
import Logo from './assets/images/logo'
import Phone from './assets/images/phone'
import RequestBtnSvg from '../components/assets/images/request-quote-btn'
import LetsTalkPopup from './lets-talk-popup'
import LanguageSwitcher from './language_switcher'
const Navbar = ({page})=> {
   const [showrequestpop,setShowrequestpop] = useState(false)
   const [mobilemenu,setMobilemenu] = useState(false)
   const handlePopup =(e)=>{
    if(e === 'close'){
      setShowrequestpop(false)
      if(window){
         window.scrollTo(0,0)
      }
    }else if (e === 'open'){
      setShowrequestpop(true)
    }else if (e === 'toggle'){
      setShowrequestpop(!showrequestpop)
    }
    setMobilemenu(false)
   }

   const handlePagetop =(e)=>{
      if(window){
      if(e === "/cases"){
         sessionStorage.removeItem('case_list_y')
      }else if(e === "/blog"){
         sessionStorage.removeItem('blog_list_y')
      }
      // navigate(e)
   }
   }
   useEffect(()=>{

   },[showrequestpop,mobilemenu])
   const handleMobileMenu = (e) =>{
      if(e === 'close'){
         setMobilemenu(false)
       }else if (e === 'open'){
         setMobilemenu(true)
       }else if (e === 'toggle'){
         setMobilemenu(!mobilemenu)
       }
     
   }
    return (
       <React.Fragment> 

      <div className="row-fluid-wrapper row-depth-1 row-number-3 custom-header-wrapper">
      <div className="row-fluid ">
         <div className="span12 widget-span widget-type-cell ">
            <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
               <div className="row-fluid">
                  <div className="span12 widget-span widget-type-cell custom-head-wrap">
                     <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                        <div className="row-fluid ">
                           <div className="span3 widget-span widget-type-raw_html custom-logo">
                              <div className="cell-wrapper layout-widget-wrapper">
                                 <span id="hs_cos_wrapper_module_1496999575486116" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html" >
                                <Link to="/" className="logo">
                                    <Logo/>
                                    </Link>
                                 </span>
                              </div>
                              
                           </div>
                           
                           <div className="span9 widget-span widget-type-cell custom-header-right without-language">
                              <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                                 <div className={`row-fluid ${mobilemenu && `show-mobile-nav`}`}>
                                    <div className={`span8 widget-span widget-type-menu custom-menu-primary first`}>
                                       <div className="cell-wrapper layout-widget-wrapper">
                                          <span id="hs_cos_wrapper_module_13884994340213" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu">
                                             <div id="hs_menu_wrapper_module_13884994340213" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal">
                                                <a className="close-icon" onClick={()=>handleMobileMenu('close')}><span></span></a>
                                                <ul className="active-branch">
                                                   <li className="hs-menu-item">
                                                   <Link  to="/" activeClassName="active">
                                                   <div className="en-text">
                                                         The essence
                                                      </div>                                                      
                                                   </Link>                                                      
                                                   </li>
                                                   <li className="hs-menu-item">
                                                   <Link activeClassName="active" to='/cases' onClick={()=>handlePagetop("/cases")}>
                                                      <div className="en-text" >
                                                          Cases
                                                         </div>                                                      
                                                      </Link>
                                                   </li>
                                                   <li className="hs-menu-item">
                                                   <Link to='/blog' activeClassName="active" onClick={()=>handlePagetop("/blog")}>
                                                      <div className="en-text" >
                                                          Blog
                                                         </div>                                                      
                                                      </Link> 
                                                    </li>
                                                   <li className="hs-menu-item">
                                                   <Link activeClassName="active" to='/about'>
                                                      <div className="en-text" >
                                                          About
                                                         </div>                                                      
                                                      </Link>
                                                   </li>
                                                   <li className="visible-phone">
                                                      <div className="custom-header-phone">
                                                      <a href='#' onClick={()=>handlePopup(`open`)}>
                                                      {' '}
                                               <Phone/>
                                               {' '}
                                                Contact                                                    
                                                      </a>
                                                      </div>
                                                   </li>
                                                </ul>
                                                <div className="lang_switcher_class">
                                      
                                      <LanguageSwitcher/>
                                      </div>
                                             </div>
                                          </span>
                                       </div>
                                       
                                    </div>
                                    <a className="mobile-icon" onClick={()=>handleMobileMenu('open')}><span></span></a>
                                    <a className="mobile-phone" onClick={()=>handlePopup(`open`)}><span><i className="fa fa-phone"></i></span></a>
                                    <div className="span2 widget-span widget-type-raw_html custom-header-phone header-phone">
                                       <div className="cell-wrapper layout-widget-wrapper">
                                          <span id="hs_cos_wrapper_module_1497415949211101" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
                                            
                                             <a href="#" className="header-contact-btn en-text"  onClick={()=>handlePopup(`open`)}>
                                                {' '}
                                               <Phone/>
                                               {' '}
                                                Contact
                                             </a>
                                            
                                          </span>
                                       </div>
                                       
                                    </div>
                                    
                                    {/* <div className="span1 widget-span widget-type-raw_jinja language-switcher-main desktop-switch">
                                       <div className="lang_switcher_class">
                                      
                                       <LanguageSwitcher/>
                                       </div>
                                    </div> */}
                                    
                                   
                                    
                                 </div>
                                 
                              </div>
                              
                           </div>
                           
                        </div>
                        
                     </div>
                     
                  </div>
                  
               </div>
               
            </div>
            
         </div>
         
      </div>
      
   </div>
   <div className="request-quote-btn" onClick={()=>handlePopup(`open`)}>
          <RequestBtnSvg/>
    </div>
       <LetsTalkPopup show={showrequestpop} handlePopup={handlePopup}/>

   </React.Fragment>
    )
  
}

export default Navbar
