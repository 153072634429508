import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import useSiteMetadata from "../components/SiteMetadata";
import { withPrefix } from "gatsby";
import "../components/assets/styles/all.scss";

const TemplateWrapper = ({ children, bulb, page, pageloaded }) => {
  const { title, description } = useSiteMetadata();
  useEffect(() => {}, [bulb]);
  return (
    <div>
      <Helmet defer={false}>
        <html
          lang="en"
          className={`${page} ${pageloaded ? "overflowhide" : ""}`}
        />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <meta name="theme-color" content="#fff" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
        />
        <meta
          name="ahrefs-site-verification"
          content="fe6df8bef8183a8a870490039f3206e2aa100ceb9471f69144011217df39d7e9"
        />
      </Helmet>

      <div className={`${bulb ? "bulb_on" : ""}`}>
        <Navbar page={page} />
        {children}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script defer type="application/javascript" src="https://io.clickguard.com/s/cHJvdGVjdG9y/KZjOzTjS"></script>
          <script defer type="application/javascript">
          (function () {
              window.cg_convert = function (x, y) { if (window._cg_convert) window._cg_convert(x || null, y || null); else setTimeout(function () { cg_convert(x || null, y || null) }, 500); };
          })();
          </script>
        `,
        }}
      />
    </div>
  );
};

export default TemplateWrapper;
