import React from 'react'
import './style.scss'

const Logo = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 188.887 42.946">
<g id="group-69">
<g id="group">
<g id="group-2" data-name="group">
<path id="path" className="logo1" d="M65.819,5.835l-6.3.467A22.5,22.5,0,0,0,44.113,0,20.792,20.792,0,0,0,26.141,10.27h0c-.467.7-.7,1.4-1.167,2.1a9.738,9.738,0,0,0-8.4-3.968h0c-.467,0-1.167.233-1.634.233C10.737,9.336,0,11.9,0,11.9S8.869,24.741,10.5,26.141a9.918,9.918,0,0,0,7.469,2.334c-3.034,7.469-5.835,14.471-5.835,14.471s32.91,0,37.111-1.4A21.015,21.015,0,0,0,65.119,21.24a22.127,22.127,0,0,0-1.634-8.169ZM45.28,36.411a16.1,16.1,0,1,1,16.1-16.1A16.137,16.137,0,0,1,45.28,36.411Z"></path>
</g>
<text id="JD" className="logo2" transform="translate(77.643 29.677)"><tspan x="0" y="0">JD</tspan></text>
<text id="SOLUTIONS" className="logo3" transform="translate(121.887 26.347)"><tspan x="0" y="0">SOLUTIONS</tspan></text>
</g>
<circle id="ellipse" className="logo1" cx="4.201" cy="4.201" r="4.201" transform="translate(45.047 13.071)"></circle>
</g>
</svg>
    )
}
export default Logo;