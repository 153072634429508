import React, { useState, useEffect } from 'react'

import Caret from '../assets/images/caret'
import './style.scss'

const windowGlobal = typeof window !== 'undefined' && window
const LanguageSwitcher =()=>{
    const [langmenu,setLangmenu] = useState(false)
    const [langoptions,setLangoptions] = useState([
                                        { value: 'nl', label: 'NL' },
                                        { value: 'en', label: 'EN' },
                                        { value: 'fr', label: 'FR' }
                                    ])
    const [selectlang,setSelectlang]  = useState(langoptions[1].label)                             
    const handleLangMenu = (e) =>{
        if(e === 'close'){
            setLangmenu(false)
        }else if(e === 'open'){
            setLangmenu(true)
        }else if(e === 'toggle'){
            setLangmenu(!langmenu)
        }else{
            setSelectlang(langoptions[e].label)
            setTimeout(() => {
                setLangmenu(false)
            }, 200);
        }
    }
    const getLocationHistory = () =>{
        fetch('https://api.ipgeolocation.io/ipgeo?apiKey=210515b761264bdd9f8d95c1104cdd8b')
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
               if(windowGlobal){              
                Object.keys(myJson).forEach(el=>{
                    if(el === `ip` 
                    || el === `continent_code` 
                    || el === `continent_name` 
                    || el === `country_code2` 
                    || el === `country_code3` 
                    || el === `country_name` 
                    || el === `country_capital`
                    || el === `state_prov`
                    || el === `city`
                    || el === `zipcode`
                    || el === `latitude`
                    || el === `longitude`
                    || el === `languages`){
                        windowGlobal.localStorage.setItem(el,myJson[el])
                    }
                   
                })
            }
        });
    
  
    }
    useEffect(()=>{
        getLocationHistory()
    },[langmenu,selectlang])
    return(
             <div className={`globe_class disabled ${langmenu ? `arractive` : ``}`}>
                <span className="lang-btn"
                //  onClick={()=>handleLangMenu('toggle')}
                 >
                <span className="main_lang">{selectlang}</span>
                <Caret/> 
                </span>
                {langmenu 
                && 
                <ul className="lang_list_class" style={{display:'block'}}>
                {langoptions.map((d,i)=>{return <li key={`lang${i}`} onClick={()=>handleLangMenu(i)}>
                    <a className="lang_switcher_link" href="javascript:;">{d.label}</a>
                    </li>})}
                </ul>}
            </div>
    )
}
export default LanguageSwitcher