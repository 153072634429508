const windowGlobal = typeof window !== 'undefined' && window

const getLocationData =(ldata)=>{
    if(windowGlobal){
        if(windowGlobal.localStorage.getItem(ldata)){
            return windowGlobal.localStorage.getItem(ldata)
        }else {
            return null
        }
    }
}

export {getLocationData}