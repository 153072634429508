import React from 'react'
const styleconst=({enableBackground:'new 0 0 113.3 100'})
const RequestBtnSvg = () =>{
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 113.3 100" style={styleconst} xmlSpace="preserve">
<title>quote-request</title>
<path className="st0" fill="#fff" d="M87.3,100H22.9c-2.7,0-4.9-2.5-4.9-5.6V5.6C18,2.5,20.2,0,22.9,0h64.4c2.7,0,4.9,2.5,4.9,5.6v88.8
C92.2,97.5,90,100,87.3,100z M22.9,5.5l-0.1,88.8l64.5,0.1c0,0,0,0,0.1-0.1V5.6L22.9,5.5z"></path>
<rect x="32.7" y="18.4" className="st0" fill="#fff" width="45.9" height="5"></rect>
<rect x="32.7" y="28.6" className="st0" fill="#fff" width="45.9" height="5"></rect>
<rect x="32.7" y="38.9" className="st0" fill="#fff" width="26.4" height="5"></rect>
<rect x="32.7" y="59.4" className="st0" fill="#fff" width="36.3" height="5"></rect>
<rect x="32.7" y="49.1" className="st0" fill="#fff" width="29.6" height="5"></rect>
<polygon className="st0" fill="#fff" points="68.5,55 60.7,37.1 80.3,37.3 "></polygon>
<polygon className="st0" fill="#fff" points="70,56.3 81.9,38.6 112.6,58.7 100.7,76.4 "></polygon>
</svg>
    )
}
export default RequestBtnSvg